<!--
 * @Author: yuwenwen
 * @Date: 2022-11-11 17:10:46
 * @LastEditors: Do not edit
 * @LastEditTime: 2022-12-12 14:40:53
 * @FilePath: \nc-funeral-mobile\src\views\wxLogin.vue
-->
<template>
  <div>
    <van-popup v-model:show="show" :close-on-click-overlay="false" style="border-radius:6px;">
      <van-form @submit="login" style="margin:50px auto;">
        <van-field v-model="username" name="账号" label="账号" placeholder="账号"
          :rules="[{ required: true, message: '请填写账号' }]" label-align="center" />
        <van-field v-model="password" type="password" name="密码" label="密码" placeholder="密码"
          :rules="[{ required: true, message: '请填写密码' }]" label-align="center" />
        <div style="margin: 16px">
          <van-button round block type="primary" native-type="submit">
            登录
          </van-button>
        </div>
      </van-form>
    </van-popup>
  </div>
</template>
<script>
import { getRedirctUrl } from "@/api/login.js";
// import { getQueryVariable } from "@/utils/funeral.js";
import {
  getToken,
} from "@/store/auth";
export default {
  name: "WeixnLogin",
  data() {
    return {
      username:'',
      password:'',
      show:true,
    };
  },
  created() {
    // let code = getQueryVariable("code");
    // this.login(code);

  },
  mounted() { },
  methods: {
    async isWxLogin() {
      // 获取跳转地址
      let [err, result] = await this.$awaitWrap(getRedirctUrl());
      if (err) {
        return;
      }
      window.location.href = result.data;
    },

    // 登录(用code换取token和openid) -微信登陆
    // async login(code) {
    //   if (code) {
    //     let [err, result] = await this.$store.dispatch('userLogin', { code });
    //     if (err) {
    //       // 弹出信息
    //       return;
    //     }
    //     this.$router.replace({ path: '/' })
    //     console.log(result)

    //   } else {
    //     this.isWxLogin()
    //   }
    // },

    //  -本地登陆
    async login() {
      let parameter = {
        username: this.username,
        password: this.password,
      };
      console.log(getToken());
      if (!getToken()) {
        let [err, result] = await this.$store.dispatch('localUserLogin', { parameter });
        if (err) {
          // 弹出信息
          return;
        }
        this.$router.replace({ path: '/' })
        console.log(result)

      } else {
        this.isWxLogin()
      }
    },
  },
};
</script>
<style scoped>

</style>
